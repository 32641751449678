import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Avatar, Link, Button} from '@mui/material';
import { keyframes } from '@emotion/react';
import { Twitter, ExternalLink, Book, Code, Lightbulb } from 'lucide-react';
import { Article, Pets, Celebration, GitHub } from '@mui/icons-material';

const morphBlob = keyframes`
  0%, 100% { border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%; }
  50% { border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%; }
`;

const float = keyframes`
  0%, 100% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const generateRandomPosition = () => ({
  top: `${Math.random() * 100}%`,
  left: `${Math.random() * 100}%`,
});


const Home = () => {
  const [scrolled, setScrolled] = useState(false);
  const containerRef = useRef(null);
  const [blobPositions] = useState([...Array(12)].map(() => generateRandomPosition()));

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setScrolled(scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const products = [
    {
      name: "マッチョになろう!",
      description: "Webで筋トレができます。肩にちっちゃい重機乗せれるくらい鍛えれちゃうかもしれません🔥仕上がってきた方はご連絡ください。",
      image: "Super.png",
      url: "https://pikutraining.pages.dev/",
      icon: <Pets />
    },
    {
      name: "トレンド俳句",
      description: "ネットのトレンドを季語として使い俳句を作ろう!",
      image: "icon.png",
      url: "https://trendhaiku.online/",
      icon: <Article />
    },
    {
      name: "鬱憤を晴らす(物理)",
      description: "日頃の溜まった鬱憤を晴らせます(物理)気が済むまで晴らし放題です。",
      image: "Uppun.png",
      url: "https://uppun-harasu.pages.dev/",
      icon: <Celebration />
    },
    {
      name: "エビ揉め!",
      description: "Every momentにエビ揉め!",
      image: "ebi.png",
      url: "https://ebi-mome.pages.dev/",
      icon: <Celebration />
    },
  ];

  const articles = [
    {
      url: "https://note.com/embed/notes/n1d71e7b0e410",
    },
    {
      url: "https://note.com/embed/notes/n2ce6c89c7b8e" ,
    },
    {
      url: "https://note.com/embed/notes/nf81313d8361f",
    },
    {
      url: "https://note.com/embed/notes/n502269e48ed7",
    }
  ];

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        minHeight: '200vh',
        backgroundColor: '#f0f4c3',
        overflow: 'hidden',
      }}
    >
      {/* Blobs */}
      {blobPositions.map((position, index) => (
        <Box
          key={index}
          className="blob"
          sx={{
            position: 'absolute',
            width: `${150 + Math.random() * 200}px`,
            height: `${150 + Math.random() * 200}px`,
            backgroundColor: `hsl(${Math.random() * 360}, 70%, 80%)`,
            borderRadius: '60% 40% 30% 70% / 60% 30% 70% 40%',
            animation: `${morphBlob} ${8 + Math.random() * 10}s ease-in-out infinite alternate, ${float} ${5 + Math.random() * 5}s ease-in-out infinite alternate`,
            opacity: 0.8,
            ...position,
            zIndex: 1,
            filter: 'blur(4px)',
          }}
        />
      ))}

      {/* Main Content */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          position: 'relative',
          zIndex: 10,
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            fontFamily: 'Comic Sans MS, cursive',
            color: '#ff6b6b',
            fontSize: '6rem',
            textShadow: '4px 4px 8px rgba(0,0,0,0.2)',
            zIndex: 10,
            mb: 2,
            textAlign: 'center',
          }}
        >
          にゃーんねっと
        </Typography>
      </Box>

      {/* Scrollable Content */}
      <Box
        sx={{
          padding: '50px 20px',
          opacity: scrolled ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
          position: 'relative',
          zIndex: 10,
        }}
      >
        {/* About Cats */}
        <Box
          sx={{
            maxWidth: '800px',
            margin: '0 auto 60px',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: '16px',
            padding: '30px',
            boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
            animation: scrolled ? `${fadeIn} 1s ease-out` : 'none',
            backdropFilter: 'blur(10px)',
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ color: '#ff6b6b', fontFamily: 'Comic Sans MS, cursive' }}>
            ニャンコについて
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#4a4e69', lineHeight: 1.8 }}>
            ニャンコは、私たちの生活に欠かせない愛らしい存在です。その柔らかな毛並み、くりくりした目、そして甘えん坊な性格は、多くの人々の心を癒しています。
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#4a4e69', lineHeight: 1.8 }}>
            ニャンコの歴史は古く、古代エジプトでは神として崇められていました。現代では、ペットとして世界中で愛されており、その独特な性格と愛らしい仕草で、多くの人々に幸せを与えています。
          </Typography>
          <Typography variant="body1" sx={{ color: '#4a4e69', lineHeight: 1.8 }}>
            ニャンコは単なるペット以上の存在です。彼らは家族の一員であり、私たちの人生に喜びと驚きをもたらしてくれる大切な友人なのです。
          </Typography>
        </Box>

        {/* Products */}
        <Typography variant="h4" gutterBottom sx={{ color: '#ff6b6b', textAlign: 'center', marginBottom: '40px', fontFamily: 'Comic Sans MS, cursive' }}>
          プロダクト
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '40px', marginBottom: '60px' }}>
          {products.map((product, index) => (
            <Card 
              key={index} 
              sx={{ 
                width: 400, 
                height: 500, 
                display: 'flex', 
                flexDirection: 'column',
                cursor: 'pointer', 
                transition: 'all 0.3s ease-in-out', 
                '&:hover': { 
                  transform: 'translateY(-10px) rotate(2deg)', 
                  boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)'
                },
                animation: `${fadeIn} 1s ease-out ${index * 0.3}s both`,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)',
              }}
              onClick={() => window.open(product.url, '_blank')}
            >
              <CardMedia
                component="img"
                height="220"
                image={product.image}
                alt={product.name}
              />
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>
                  <Typography gutterBottom variant="h5" component="div" sx={{ color: '#ff6b6b', fontFamily: 'Comic Sans MS, cursive', display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {product.icon}
                    {product.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {product.description}
                  </Typography>
                </Box>
                <Button 
                  variant="contained" 
                  endIcon={<ExternalLink />}
                  sx={{ 
                    mt: 2, 
                    bgcolor: '#ff6b6b', 
                    '&:hover': { 
                      bgcolor: '#ff8585'
                    } 
                  }}
                >
                  詳細を見る
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>

        {/* Articles */}
        <Typography variant="h4" gutterBottom sx={{ color: '#ff6b6b', textAlign: 'center', marginBottom: '40px', fontFamily: 'Comic Sans MS, cursive' }}>
  記事
</Typography>
<Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '40px', marginBottom: '60px' }}>
  {articles.map((article, index) => (
    <Box 
      key={index} 
      sx={{ 
        width: 400, // プロダクトと同じ横幅に調整
        maxWidth: '99%', 
        padding: '0px', 
        margin: '10px 0px', 
        position: 'static',
        visibility: 'visible',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <iframe
        src={article.url}
        style={{
          border: '0',
          display: 'block',
          width: '100%', // 親要素にフィットさせる
          maxWidth: '100%',
          height: '200px', // 任意の高さに調整可能
          margin: '0px',
          padding: '0px',
        }}
        title={article.title}
      ></iframe>
    </Box>
  ))}
</Box>

        

        {/* Profile */}
        <Box sx={{ textAlign: 'center', marginBottom: '60px', animation: scrolled ? `${fadeIn} 0.3s ease-out 0.3s both` : 'none' }}>
          <Avatar
            alt="Profile Picture"
            src="n.jpg"
            sx={{ 
              width: 180, 
              height: 180, 
              margin: '0 auto 30px', 
              border: '4px solid #ff6b6b',
              boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
            }}
          />
          <Typography variant="h4" gutterBottom sx={{ color: '#4a4e69', fontFamily: 'Comic Sans MS, cursive' }}>
            ねこじゃらしさん
          </Typography>
          <Box sx={{ flexDirection: 'row', flexDirection: 'column' ,alignItems: 'center'}}>
  <Link 
    href="https://x.com/nekozyarasisan" 
    target="_blank" 
    rel="noopener noreferrer" 
    sx={{ 
      display: 'inline-flex', 
      alignItems: 'center', 
      color: '#1DA1F2', 
      marginRight: '20px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }}
  >
    <Twitter size={24} style={{ marginRight: '8px' }} />
    Twitter
  </Link>

  <Link 
    href="https://github.com/zyousiikuta" 
    target="_blank" 
    rel="noopener noreferrer" 
    sx={{ 
      display: 'inline-flex', 
      alignItems: 'center', 
      color: '#333', 
      marginBottom: '20px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }}
  >
    <GitHub size={24} style={{ marginRight: '8px' }} />
    GitHub
  </Link>
</Box>

          <Typography variant="body1" sx={{ maxWidth: '600px', margin: '0 auto', color: '#4a4e69', lineHeight: 1.8 }}>
            個人開発してるよ!<br/>
            猫は飼ったことないです
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;